// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import classnames from 'classnames'

import map from 'lodash/map'
import isNull from 'lodash/isNull'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import Iframe from 'react-iframe'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Slider from '../slider'
import '../slider/style.less'

import Gallery from '../gallery'
import '../gallery/style.less'

import Audio from '../audio'
import '../audio/style.less'

import Video from '../video'
import '../video/style.less'

import Link from '../link'
import '../link/style.less'

import SliderRenderer from './slide'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** BlockParser */
const BlockParser = ({ block, locale = 'en' }) => {
  const {
    type,
    subType = undefined,
    lang = 'en',
    content,
    assets,
    smallAssets,
    image,
    resource,
    resources,
  } = block

  let returnThis = <Fragment />

  if (lang === locale) {
    if (type === 'space') {
      returnThis = <br />
    }

    if (type === 'slider') {
      returnThis = <Slider nodes={resources} Renderer={SliderRenderer} />
    }

    if (type === 'text') {
      returnThis = (
        <p className={classnames(subType)}>
          {compiler(content, {
            wrapper: null,
            overrides: {
              a: {
                component: Link,
              },
            },
          })}
        </p>
      )
    }

    if (type === 'title') {
      returnThis = (
        <h2 className={classnames(subType)}>
          {compiler(content, { wrapper: null })}
        </h2>
      )
    }

    if (type === 'sub-title') {
      returnThis = (
        <h3 className={classnames(subType)}>
          {compiler(content, { wrapper: null })}
        </h3>
      )
    }

    if (type === 'sub-sub-title') {
      returnThis = (
        <h4 className={classnames(subType)}>
          {compiler(content, { wrapper: null })}
        </h4>
      )
    }

    if (type === 'image' && subType !== 'cover') {
      returnThis = (
        <div className={classnames('flow', subType)}>
          <GatsbyImage image={getImage(resource)} />
        </div>
      )
    }

    if (type === 'gallery') {
      const images = []
      map(resources, ({ resource, caption: galleryImageCaption }) => {
        images.push({
          data: resource,
          caption:
            isNull(galleryImageCaption) === false
              ? galleryImageCaption
              : undefined,
          height: resource.childImageSharp.gatsbyImageData.height,
          width: resource.childImageSharp.gatsbyImageData.width,
        })
      })

      returnThis = <Gallery className={classnames(subType)} images={images} />
    }

    if (type === 'small-gallery') {
      const images = []

      map(smallAssets, ({ image: i }) => {
        images.push({
          data: i,
          height: i.childImageSharp.gatsbyImageData.height,
          width: i.childImageSharp.gatsbyImageData.width,
        })
      })

      returnThis = (
        <Gallery
          images={images}
          forceRowHeight
          className={classnames('flow', subType)}
        />
      )
    }

    if (type === 'video') {
      returnThis = <Video url={content} />
    }

    if (type === 'buzzsprout') {
      const buzzsproutUrl = `https://www.buzzsprout.com/1897213?artist=&client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F1897213%2Fpodcast%2Fembed&tags=${content}`

      returnThis = (
        <Iframe
          url={buzzsproutUrl}
          width="100%"
          height="370px"
          display="initial"
          position="relative"
        />
      )
    }

    if (type === 'audio') {
      returnThis = <Audio url={content} />
    }
  }

  return returnThis
}

/** Wrapper */
const Wrapper = ({ block, locale }) => {
  const {
    type,
    subType = undefined,
    contentBlocks: childContentBlocks = {},
  } = block

  let returnThis = <Fragment />

  if (type === 'section') {
    returnThis = (
      <section className={classnames(subType)}>
        {map(childContentBlocks, (block2) => (
          <BlockParser block={block2} locale={locale} />
        ))}
      </section>
    )
  } else if (type === 'aside') {
    returnThis = (
      <aside className={classnames(subType)}>
        {map(childContentBlocks, (block2) => (
          <BlockParser block={block2} locale={locale} />
        ))}
      </aside>
    )
  } else if (type === 'two-col') {
    const { col1, col2 } = block
    returnThis = (
      <Row className={classnames('flow', subType)}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          {map(col1, (block2) => (
            <BlockParser block={block2} locale={locale} />
          ))}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          {map(col2, (block2) => (
            <BlockParser block={block2} locale={locale} />
          ))}
        </Col>
      </Row>
    )
  } else {
    returnThis = <BlockParser block={block} locale={locale} />
  }

  return returnThis
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Wrapper
