// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

import classnames from 'classnames'
import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'
import split from 'lodash/split'
import trim from 'lodash/trim'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Iframe from 'react-iframe'
import { graphql } from 'gatsby'
import { compiler } from 'markdown-to-jsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { injectIntl } from 'gatsby-plugin-react-intl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import BlockParser from '../block-parser'
import '../block-parser/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

import flattenGatsbyNode from '../../methods/flatten-gatsby-node'

import AudioSymbol from '../svg/audio'

import { updateAudioPlayerState } from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query MagPageQuery($pathAuto: String) {
    thisPage: allFile(filter: { fields: { pathAuto: { eq: $pathAuto } } }) {
      edges {
        node {
          fields {
            pathAuto
            publishedUnixTimestampAuto
            publishedLongHumandateAuto
            lastModifiedHumandateAuto
            draftAuto
            breadcrumbsAuto {
              title
              slug
            }
          }
          childResources {
            title
            subTitle
            altTitle
            buzzsproutTag
            format
            openAccessUrl
            contentBlocks {
              type
              subType
              content
              resource {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              caption
              resources {
                resource {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: TRACED_SVG
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({
  data,
  pageContext,
  audioPlayerState,
  audioPlayerState: { active: audioPlayerActive },
  updateAudioPlayerStateFX,
  ...props
}) => {
  const {
    thisPage: {
      edges: [{ node: n }],
    },
    node = flattenGatsbyNode(n),
  } = data

  const {
    title,
    subTitle,
    altTitle,
    contentBlocks,
    pathAuto,
    publishedLongHumandateAuto,
    breadcrumbsAuto,
    buzzsproutTag,
    format,
    avUrl = null,
    openAccessUrl,
  } = node

  const pageSchema = {
    title,
    slug: pathAuto.substring(1),
    abstract: title,
    breadcrumbs: breadcrumbsAuto,
  }

  let coverImage = undefined
  const coverImageIndex = findIndex(
    contentBlocks,
    (o) => o.type === 'image' && o.subType === 'cover'
  )

  if (coverImageIndex !== -1) {
    coverImage = contentBlocks[coverImageIndex]
  }

  let tag, buzzsproutUrl
  if (isNull(buzzsproutTag) === false) {
    tag = buzzsproutTag.split(' ').join('+')
    buzzsproutUrl = `https://www.buzzsprout.com/1897213?artist=&client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F1897213%2Fpodcast%2Fembed&tags=${tag}`
  }

  return (
    <StandardPageWrapper
      className="article-page"
      pageSchema={pageSchema}
      {...props}
    >
      {isUndefined(coverImage) === false && (
        <div className={classnames('flow with-caption cover1')}>
          <GatsbyImage image={getImage(coverImage.resource)} />
          <p className="flow caption">
            {compiler(coverImage.caption, { wrapper: null })}
          </p>
          <h1>{isNull(altTitle) === false ? altTitle : title}</h1>
          {isNull(subTitle) === false && <h2>{subTitle}</h2>}
        </div>
      )}
      {isUndefined(coverImage) === true && (
        <Fragment>
          <h1>{isNull(altTitle) === false ? altTitle : title}</h1>
          {isNull(subTitle) === false && <h2>{subTitle}</h2>}
          {isNull(openAccessUrl) === false && (
            <p style={{ marginTop: '-0.75rem', marginBottom: '1rem' }}>
              <Link to={openAccessUrl}>Read on gift-economy&nbsp;⟶</Link>
            </p>
          )}
        </Fragment>
      )}
      {isNull(buzzsproutTag) === false && (
        <Iframe
          url={buzzsproutUrl}
          width="100%"
          height="375px"
          display="initial"
          position="relative"
        />
      )}
      {map(contentBlocks, (block) => (
        <BlockParser block={block} />
      ))}
      {isNull(format) === false && (
        <div className="info">
          <h2>Details</h2>
          <p>
            {map(split(format, ','), (s) => (
              <small>
                {trim(s)}
                <br />
              </small>
            ))}
          </p>
        </div>
      )}
      {isNull(openAccessUrl) === false && (
        <p>
          <Link to={openAccessUrl} className="tag">
            <span>Read on gift-economy&nbsp;&nbsp;</span>
            <span>ᐉ</span>
          </Link>
        </p>
      )}
      {isNull(avUrl) === false && (
        <p>
          <Link to={avUrl} className="tag">
            <span>Buy on auroville.com&nbsp;&nbsp;</span>
            <span>ᐉ</span>
          </Link>
        </p>
      )}
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    audioPlayerState: state.audioPlayerState,
  }),
  (dispatch) => ({
    updateAudioPlayerStateFX(payload) {
      dispatch(updateAudioPlayerState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState, // Add state
  injectIntl
)(Page)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedPage
